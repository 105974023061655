"use client";
import { useEffect, useState } from "react";
import Link from "next/link";

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = getCookie("cookieConsent");
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    setCookie("cookieConsent", "accepted", 365);
    setIsVisible(false);
  };

  const handleDecline = () => {
    setCookie("cookieConsent", "declined", 365);
    setIsVisible(false);
  };

  const setCookie = (name: string, value: string, days: number) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  };

  const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(";").shift();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className="z-10 fixed bottom-0 w-full bg-gray-700 text-white p-4 flex flex-col md:flex-row items-center justify-between shadow-lg">
      <p className="mb-4 md:mb-0">
        We use cookies to improve your experience. By using our site, you agree to our <Link
        href={"/terms-and-conditions"}>cookie policy</Link>.
      </p>
      <div className="flex space-x-4">
        <button
          onClick={handleAccept}
          className="btn text-xs py-2 px-4"
        >
          Accept
        </button>
        <button
          onClick={handleDecline}
          className="btn-dark text-xs py-2 px-4"
        >
          Decline
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
